@use './styles/variables' as *;
@use './styles/fonts' as *;
@use './styles/mixins' as *;

html {
	font-family: 'Lato', sans-serif;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	padding: 0;
	margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

button {
	border: 0;
	background: transparent;
}

body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	width: 100%;
	height: auto;
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: #2f3439;
}

a,
a:visited {
	text-decoration: none;
}

.container {
	width: 100%;
}

.header {
	background-color: #222;
}
