@use '../../styles/mixins' as *;

.navbar {
	max-width: 90rem;
	background-color: #222;
	color: white;
	margin-inline: auto;

	.navbar-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1rem;
		padding: 0 2rem;
		margin: 0 auto;
		height: 80px;

		.navbar-logo {
			&__link {
				cursor: pointer;
			}

			&__img {
				max-width: 6rem;

				@include tablet {
					max-width: 7rem;
				}
			}
		}

		.navbar-toggle {
			display: none;

			@media (max-width: 50rem) {
				display: block;
				background: none;
				border: none;
				cursor: pointer;
				font-size: 1.4rem;
				color: #fff;
			}
		}

		.navbar-menu {
			list-style: none;
			margin: 0;
			padding: 0;
			display: grid;
			grid-template-columns: repeat(4, auto);
			line-height: 5;
			padding: 0 1rem;
			margin-left: -2rem;

			@media (max-width: 50rem) {
				display: none;
				flex-direction: column;
				position: absolute;
				top: 80px;
				width: 100%;
				background-color: #222;
				padding: 1rem;
				z-index: 1;
				line-height: 3;
				text-align: center;
				transition: height 0.3s ease;

				&.active {
					display: flex;
				}
			}

			&__item {
				cursor: pointer;
				&:hover {
					background-color: rgb(224, 172, 40);
				}

				@include tablet {
					margin: 0 1rem;
					display: flex;
					width: 100%;
					justify-content: center;
				}
			}

			&__link {
				color: #fff;
				text-decoration: none;
				transition: background-color 0.3s;
				text-transform: uppercase;
			}
		}
	}
}
