@use './variables' as *;

@mixin mobile {
	@media screen and (min-width: $mobile-breakpoint) {
		@content;
	}
}

@mixin tablet {
	@media screen and (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop-sm {
	@media screen and (min-width: $desktop-sm-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: $desktop-breakpoint) {
		@content;
	}
}
@mixin desktop-xl {
	@media screen and (min-width: $desktop-xl-breakpoint) {
		@content;
	}
}

@mixin underline {
	&::after {
		content: '';
		border-bottom: 4px solid #feb508;
		width: 40px;
		display: block;
		margin-top: 0.3rem;
	}
}
@mixin white-underline {
	&::after {
		content: '';
		border-bottom: 4px solid #fff;
		width: 40px;
		display: block;
		margin-top: 0.3rem;
	}
}

@mixin border-left {
	&::before {
		content: '';
		border-left: 4px solid #feb508;
		display: block;
	}
}
