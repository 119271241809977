@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.contact-form {
	background-color: #feb508;
	color: white;
	margin-inline: auto;
	display: flex;
	flex-direction: column;

	@include tablet {
		flex-direction: row;
	}
}

.form-container {
	flex: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 0.5rem;
}

.form {
	display: flex;
	flex-direction: column;
	align-content: center;
	gap: 1rem;
	margin: 0 1rem;
	transition: all 0.5s ease;
	width: 90%;

	@include desktop-sm {
		width: 50%;
	}

	&__title {
		font-family: 'Rubik Mono One', sans-serif;
		font-size: 1.5rem;
		color: #2f3439;
		@include white-underline;

		@include desktop-sm {
			font-size: 2rem;
		}
	}

	&__input {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;

		input {
			flex: 1;
			padding: 10px;
			border-radius: none;
			// border: solid 2px #2f3439;
		}
		::placeholder {
			color: #888;
			font-style: italic;
			font-family: 'Rubik', sans-serif;
		}
	}

	&__button {
		font-size: 0.9rem;
		font-family: inherit;
		color: #2f3439;
		border: none;
		display: block;
		padding: 0.7rem;
		transition: all 0.2s ease;
		font-weight: 700;
		width: 200px;
		border: solid 2px #2f3439;
		cursor: pointer;

		&:hover {
			background-color: #2f3439;
			color: #fff;
		}
	}
	textarea {
		height: 100px;
		padding: 10px;
		resize: none;
		// border: solid 2px #2f3439;
	}
	::placeholder {
		color: #888;
		font-style: italic;
		font-family: 'Rubik', sans-serif;
	}
}

.info {
	flex: 2;
	display: flex;
	background-color: white;
	flex-direction: column;

	&__social {
		background-color: #2f3439;
		height: 85px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 3rem;
		order: 2;
		width: 100%;
		border-bottom: white 1px solid;

		@include desktop-sm {
			order: 1;
		}

		&--icon {
			color: #fff;
			font-size: 1.5rem;
		}
	}

	&__details {
		background-color: #fff;
		display: flex;
		flex-direction: column;
		color: #2f3439;
		padding: 4rem 2rem;
		gap: 1rem;
		text-align: left;
		order: 2;

		@include desktop {
			padding: 3rem 0 0 5rem;
			height: 300px;
		}

		&--title {
			font-size: $fs-150;
			display: inline-block;
			margin-bottom: 1rem;
			font-family: 'Rubik Mono One', sans-serif;
			@include underline;
		}

		&--text {
			color: #2f3439;
			font-size: $fs-100;
		}
	}
}
