@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.about {
	backdrop-filter: red;
	display: flex;
	flex-direction: column;
	height: auto;

	@include desktop-sm {
		display: grid;
		height: 750px;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-areas:
			'imagen imagen textos textos textos textos'
			'imagen imagen textos textos textos textos'
			'imagen imagen establecidos establecidos trayectoria trayectoria';
	}

	&__imagen {
		background-color: #222;
		grid-area: imagen;
		background-image: url(../../assets/images/about-us-img.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;

		@include tablet {
			background-size: cover;
		}

		@include desktop-sm {
			background-size: cover;
			height: auto;
		}
	}

	&__text {
		background-color: #f7f7f7;
		grid-area: textos;
		height: auto;
		padding: 5rem 2rem;
		transition: all 0.3s ease;
		font-weight: 300;

		@include desktop-sm {
			padding: 3rem;
			height: 650px;
		}
		@include desktop {
			padding: 3rem;
		}

		@include desktop-xl {
			height: 700px;
			padding: 6rem;
		}

		&--title {
			line-height: 1;
			font-size: calc(2vw + 1rem);
			font-family: 'Rubik Mono One', sans-serif;
			text-wrap: balance;

			@include desktop-sm {
				font-size: 1.8rem;
				padding: 0 2rem;
				margin-top: 1rem;
			}

			@include desktop {
				padding: 0 7rem;
				margin-top: 2rem;
			}

			@include desktop-xl {
				margin-top: 2rem;
			}
			@include underline;
		}

		&--paragraph {
			margin: 2rem 0 0;
			font-size: 1rem;

			@include desktop {
				padding: 0 7rem;
				font-size: $fs-100;
				columns: 2;
				column-gap: 3rem;
			}
			@include desktop-xl {
				font-size: $fs-110;
			}
		}
	}

	&__info {
		&--years {
			flex: 1;
			background-color: #feb508;
			padding: 1.5rem 0;
			grid-area: establecidos;
			text-align: center;
			@include tablet {
				height: 100px;
				padding: 0.5rem 0;
			}

			h3 {
				font-size: 2rem;
			}

			p {
				font-size: 1rem;
			}
		}

		&--projects {
			flex: 1;
			background-color: #222;
			padding: 2rem 0;
			grid-area: trayectoria;
			text-align: center;
			@include tablet {
				height: 100px;
				padding: 0.5rem 0;
			}
			h3 {
				color: #fff;
				font-size: 2rem;
			}

			p {
				color: #fff;
				font-size: 1.1rem;
			}
		}
	}
}

.break-desktop {
	@include desktop {
		display: none;
	}
}
