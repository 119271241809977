@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.hero-section {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 80vh;
	// clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 4rem), 0 100%);

	@include desktop-sm {
		background-position: center;
		background-size: cover;
	}
}

.hero-overlay {
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;

	@include desktop-sm {
		flex-direction: row;
	}

	&__box {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		padding: 7%;
		&:first-child {
			display: none;
		}

		@include desktop-sm {
			&:first-child {
				display: block;
			}
		}

		&--text {
			h1 {
				font-size: calc(2vw + 1rem);
				line-height: 1.1;
				color: #fff;
				font-family: 'Rubik Mono One', sans-serif;
				border-left: solid 5px #feb508;
				display: block;
				padding-left: 0.8rem;

				@include desktop-sm {
					font-size: 2.2rem;
				}
			}
			p {
				color: #fff;
				font-size: calc(1vw + 0.9rem);
				margin-top: 1rem;
				text-wrap: balance;
				line-height: 1.3;

				@include tablet {
					font-size: 1.3rem;
				}
			}
		}
	}
}
