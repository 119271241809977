//Breakpoints
$mobile-breakpoint: 480px;
$tablet-breakpoint: 720px;
$desktop-sm-breakpoint: 940px;
$desktop-breakpoint: 1280px;
$desktop-xl-breakpoint: 1440px;

//Primary Colors
$primary: #323232;
$primaryLight: #ffffff;
$primaryDark: #afafaf;
$primaryCta: #0095ff;

$secondary: #000000;
$secondaryLight: #fafafa;
$secondaryDark: #e1e1e1;
$secondaryCta: #0065ad;

//Font Size
$fs-075: 0.75rem;
$fs-080: 0.8rem;
$fs-085: 0.85rem;
$fs-090: 0.9rem;
$fs-100: 1rem;
$fs-110: 1.1rem;
$fs-120: 1.2rem;
$fs-130: 1.3rem;
$fs-140: 1.4rem;
$fs-150: 1.5rem;
$fs-160: 1.6rem;
$fs-170: 1.7rem;
$fs-180: 1.8rem;
$fs-200: 2rem;
$fs-250: 2.5rem;
$fs-350: 3.5rem;

//Weight
$fw-400: 400;
$fw-600: 600;
$fw-700: 700;
