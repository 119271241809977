@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.services {
	height: auto;
	width: 100%;
	margin: 5rem 0;
	padding: 0 2rem;

	@include desktop {
		max-width: 75rem;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 10rem 0 5rem;
	}

	&__title {
		line-height: 1.1;
		font-size: calc(2vw + 1rem);
		line-height: 1;
		margin-bottom: 3rem;
		font-family: 'Rubik Mono One', sans-serif;
		@include underline;
		@include desktop-sm {
			font-size: 2rem;
		}
	}
}

.services-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	row-gap: 2rem;
	gap: 2rem;
	text-align: left;
	margin-top: 2rem;
	font-family: 'Lato', sans-serif;

	@include tablet {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}

	@include desktop-sm {
		padding-bottom: 5rem;
	}

	&__service {
		border-radius: 0.5rem;
		padding: 2rem 3rem;
		// border: solid 2px #000000;
		box-shadow: 0 0 12px rgba($color: #000000, $alpha: 0.15);

		&--icon {
			width: 100px;
		}
		&--title {
			margin-top: 1rem;
			text-align: left;
			width: 100%;
			font-size: $fs-120;
			font-weight: 700;
			font-family: 'Rubik', sans-serif;
		}
		&--body {
			font-size: 1rem;
			font-weight: 300;
			margin-top: 0.5rem;
		}
	}
}
