@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.current-project {
	padding: 0 2rem;
	margin-top: 5rem;
	@include tablet {
		max-width: 80rem;
		margin: 10rem auto 0;
	}

	&__title {
		line-height: 1;
		font-size: calc(2vw + 1rem);
		font-family: 'Rubik Mono One', sans-serif;
		text-wrap: balance;
		margin-bottom: 3rem;
		@include underline;
		@include desktop-sm {
			font-size: 2rem;
		}
	}

	.slick-slide div {
		padding: 0 !important;
	}

	.slick-prev:before,
	.slick-next:before {
		color: #222 !important;
	}

	.slick-dots li button::before {
		color: #feb508 !important;
		font-size: 1rem !important;
		top: 2rem !important;
	}
}

.project-container {
	margin-top: 2rem;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	@include desktop-sm {
		align-items: flex-start;
	}

	@include desktop-sm {
		flex-direction: row;
	}
	&__image {
		@include desktop-sm {
			min-width: 65%;
		}
		&--slide {
			height: 200px;
			object-fit: cover;
			@include tablet {
				min-height: 300px;
			}
			@include desktop-sm {
				height: 450px;
			}
		}
	}
	&__text {
		// margin-top: 1rem;
		background-color: rgba($color: #2f3439, $alpha: 0.05);
		padding: 1rem;
		@include desktop {
			// margin-top: 2rem;
		}

		&--title {
			font-size: 1.1rem;
			font-weight: 700;

			@include tablet {
				font-size: 1.2rem;
			}
		}

		&--body {
			font-size: 0.9rem;
			font-weight: 300;
			margin-top: 0.5rem;
			@include tablet {
				font-size: 1rem;
			}
		}
	}
	&__list {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		span {
			font-weight: $fw-600;
		}

		&--item {
			list-style-type: none;
			border-bottom: solid 1px #2f3439;
			padding-bottom: 1rem;
			font-size: 0.9rem;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}
