@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.projects {
	padding: 5rem 2rem;
	margin-top: 5rem;
	@include desktop {
		max-width: 80rem;
		margin: 4rem auto;
	}

	&__title {
		font-family: 'Rubik Mono One', sans-serif;
		font-size: calc(2vw + 1rem);
		line-height: 1;
		@include underline;
		@include desktop-sm {
			font-size: 2rem;
		}
	}
}

.slick-slide div {
	padding: 0.5rem !important;
}

.slick-prev:before,
.slick-next:before {
	color: #222 !important;
}

.slick-dots li button::before {
	color: #feb508 !important;
	font-size: 1rem !important;
	top: 2rem !important;
}

.card-container {
	margin: 2rem 0;
	padding: 0 1rem;

	.slick-next {
		right: -25px !important;
	}

	.slick-prev {
		left: -25px !important;
	}
}

.card {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
	&__imagen {
		max-width: 100%;
		height: 200px;
		object-fit: cover;
		@include tablet {
			height: 220px;
		}
	}
}

.card__content {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	align-items: flex-start;

	&--title {
		font-size: 1.1rem;
		margin-top: 12px;
	}

	&--paragraph {
		margin-top: 10px;
		font-size: $fs-080;
		font-weight: 300;

		@include tablet {
			font-size: 1rem;
			min-height: 100px;
		}
	}

	&--button {
		margin-top: 12px;
		padding: 8px 16px;
		background-color: #2f3439;
		color: #fff;
		border: none;
		cursor: pointer;
		margin-top: 1rem;
	}
}
