@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.footer {
	margin: 0 auto;
	padding: 2rem;
	background-color: #2f3439;
	color: #fff;
	display: flex;
	align-items: center;

	@include desktop-sm {
		padding: 0 2rem;
		height: 70px;
	}
}

.footer-container {
	width: 80rem;
	margin: 0 auto;
	display: flex;
	gap: 1rem;
	flex-direction: column;
	@include tablet {
		flex-direction: row;
		align-items: center;
	}

	&__title {
		font-size: $fs-090;
		color: #afafaf;

		span {
			font-size: $fs-090;
			font-weight: $fw-700;
			text-transform: uppercase;
		}
	}

	&__copyright {
		color: #afafaf;
		flex: 1;
		font-size: $fs-090;

		a {
			color: #afafaf;
			font-weight: 700;
			text-decoration: none;
			border-bottom: 1px solid;
			transition: all 0.2s ease-in-out;

			&:hover {
				color: #fff;
			}
		}
	}
}
